import {
  copyObject,
} from '../../common/functions/helpers';

export default class OrcamentoConfiguracao {
  constructor(configuracao) {
    this.configuracao = configuracao;
  }

  get idConfiguracao() {
    return this.configuracao.id;
  }

  get nome() {
    const { passo1 } = this.configuracao;
    return passo1.nome;
  }

  get idsTipoInvestimento() {
    const { passo1 } = this.configuracao;
    return passo1.idsTipoInvestimento;
  }

  get divisoes() {
    const { passo2 } = this.configuracao;
    return passo2.listaDivisao;
  }

  get habilitaColunaDivisao() {
    const { passo2 } = this.configuracao;
    return passo2.listaDivisao.length > 0;
  }

  get colunasResumo() {
    const { passo4 } = this.configuracao;
    return passo4.listaResumo.map((coluna) => coluna.label);
  }

  get colunasDetalhesRateio() {
    if (this.ehPercentual) {
      const { passo5 } = this.configuracao;
      return passo5.listaResumo
        .filter((coluna) => coluna.habilitaGrid)
        .map((coluna) => coluna.label);
    }
    const { passo4 } = this.configuracao;
    return passo4.listaResumo.map((coluna) => coluna.label);
  }

  get colunasLinhaInvestimento() {
    const { passo4 } = this.configuracao;
    return passo4.listaLinha.map((coluna) => coluna.label);
  }

  get colunaPosseConta() {
    return this.rateioDivisao ? 'divisao' : this.composicao;
  }

  get composicao() {
    const { passo3 } = this.configuracao;
    return passo3.composicao ? passo3.composicao.valor : 'divisao';
  }

  get rateioDivisao() {
    const { passo3 } = this.configuracao;
    return passo3.formaRateio
      && passo3.formaRateio.valor === 'DIVISAO';
  }

  get fluxoHabilitado() {
    const { passo3 } = this.configuracao;
    const { fluxoAprovacao } = passo3;
    return fluxoAprovacao && fluxoAprovacao.valor === 'true';
  }

  get permiteAdendo() {
    const { passo3 } = this.configuracao;
    const { operacaoAdendo } = passo3;
    return operacaoAdendo && operacaoAdendo.valor === 'true';
  }

  get permiteVerbaParcial() {
    const { passo3 } = this.configuracao;
    const { verbaParcial } = passo3;
    return verbaParcial && verbaParcial.valor === 'true';
  }

  get permiteReducao() {
    const { passo3 } = this.configuracao;
    const { operacaoReducao } = passo3;
    return operacaoReducao && operacaoReducao.valor === 'true';
  }

  get ehPercentual() {
    const { passo3 } = this.configuracao;
    const { percentualInvest } = passo3;
    return percentualInvest && percentualInvest.valor === 'true';
  }

  get permiteTransferencia() {
    return this.permiteAdendo && this.permiteReducao;
  }

  get permiteTransferenciaAjusteVerbaDiferente() {
    const { passo3 } = this.configuracao;
    const { operacaoTranferencia } = passo3;
    return operacaoTranferencia && operacaoTranferencia.valor === 'true';
  }

  get colunasObrigatoriasLinha() {
    const { passo2 } = this.configuracao;
    const {
      listaCliente, listaDinamica, listaOutros, listaProduto,
    } = passo2;
    const listaConjunta = [
      ...listaCliente,
      ...listaDinamica,
      ...listaOutros,
      ...listaProduto,
    ];

    if (this.habilitaColunaDivisao) {
      listaConjunta.push({ label: 'divisao', obrigatorio: true, contexto: 'LINHA' });
    }

    return listaConjunta.filter((campo) => campo.contexto === 'LINHA')
      .filter((campo) => campo.obrigatorio)
      .map((campo) => campo.label);
  }

  get colunasExtensaoLinha() {
    const { passo2 } = this.configuracao;
    const { listaCliente, listaProduto } = passo2;
    const listaConjunta = [...listaCliente, ...listaProduto];
    return listaConjunta
      .filter((campo) => campo.contexto === 'LINHA')
      .filter((campo) => ['produto', 'cliente'].indexOf(campo.label) < 0)
      .map((campo) => campo.label);
  }

  get colunasDinamicasLinha() {
    const { passo2 } = this.configuracao;
    const { listaDinamica } = passo2;
    return listaDinamica
      .filter((campo) => campo.contexto === 'LINHA')
      .map((campo) => campo.label);
  }

  get isAporteManual() {
    const { passo1 } = this.configuracao;
    return passo1.comportamento
      && passo1.comportamento === 'MANUAL';
  }

  aplicaParametrizacaoResumo(metadadosOrcamento) {
    const copiaMetadados = copyObject(metadadosOrcamento);
    const contexto = 'RESUMO';
    this.parametrizaCamposDinamicos(copiaMetadados, contexto);
    this.parametrizaExtensoes(copiaMetadados, contexto);
    return copiaMetadados;
  }

  aplicaParametrizacaoLinha(metadadosLinha) {
    const copiaMetadados = copyObject(metadadosLinha);
    const contexto = 'LINHA';
    this.parametrizaCamposDinamicos(copiaMetadados, contexto);
    this.parametrizaExtensoes(copiaMetadados, contexto);

    return copiaMetadados;
  }

  /**
   * [Parametriza Campos Opcionais]
   *
   * Cruza as informacoes da configuracao com a relacao
   * de campos opcionais do resumo ou linha, aplicando
   * especificos comportamentos (ex: obrigatoriedade) da
   * configuracao - feita pelo usuário - ao campo original;
   *
   * @param {*} relacaoCampos
   * @param {*} contexto
   */
  parametrizaCamposOpcionais(relacaoCampos, contexto) {
    const { passo2 } = this.configuracao;
    const { listaCliente, listaProduto, listaOutros } = passo2;
    const listaConjunta = [...listaCliente, ...listaOutros, ...listaProduto];
    const mapaConfiguracao = {};
    listaConjunta
      .filter((campo) => campo.contexto === contexto)
      .filter((campo) => ['produto', 'cliente', 'centro_custo'].indexOf(campo.label) >= 0)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    relacaoCampos
      .filter((campo) => mapaConfiguracao[campo.labelCampo] != null)
      .forEach((campo) => {
        const campoConfiguracao = mapaConfiguracao[campo.labelCampo];
        campo.vlrObrigatorio = campoConfiguracao.obrigatorio;
      });
  }

  /**
   * [Parametriza Campos Dinamicos]
   *
   * Cruza as informacoes da configuracao com os metadados,
   * aplicando especificos comportamentos (ex: obrigatoriedade)
   * da configuracao - feita pelo usuário - ao campo original;
   *
   * @param {*} metadados
   */
  parametrizaCamposDinamicos(metadados, contexto) {
    const { mapaCamposDinamicos } = metadados;
    if (!mapaCamposDinamicos
      || Object.keys(mapaCamposDinamicos).length === 0) return;

    const { listaDinamica } = this.configuracao.passo2;
    const mapaConfiguracao = {};
    listaDinamica
      .filter((campo) => campo.contexto === contexto)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    const camposAmostragem = Object.keys(mapaCamposDinamicos);
    camposAmostragem
      .filter((chave) => mapaConfiguracao[chave] != null)
      .forEach((chave) => {
        const campoDinamico = mapaCamposDinamicos[chave], // eslint-disable-line
          campoConfiguracao = mapaConfiguracao[chave]; // eslint-disable-line

        // aplica modificacoes
        campoDinamico.vlrObrigatorio = campoConfiguracao.obrigatorio;
      });
  }

  /**
   * [Parametriza Extensoes]
   *
   * Cruza as informacoes da configuracao com os metadados,
   * aplicando especificos comportamentos (ex: obrigatoriedade)
   * da configuracao - feita pelo usuário - à extensao original;
   *
   * @param {*} metadados
   */
  parametrizaExtensoes(metadados, contexto) {
    const { mapaEntidades } = metadados;
    if (!mapaEntidades
      || Object.keys(mapaEntidades).length === 0) return;

    const { listaCliente, listaProduto } = this.configuracao.passo2;
    const listaConjunta = [...listaCliente, ...listaProduto];
    const mapaConfiguracao = {};
    listaConjunta
      .filter((campo) => campo.contexto === contexto)
      .filter((campo) => ['produto', 'cliente'].indexOf(campo.label) < 0)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    const extensoesAmostragem = Object.keys(mapaEntidades);
    extensoesAmostragem
      .filter((chave) => mapaConfiguracao[chave] != null)
      .forEach((chave) => {
        const extensao = mapaEntidades[chave], // eslint-disable-line
          campoConfiguracao = mapaConfiguracao[chave]; // eslint-disable-line

        // aplica modificacoes
        extensao.depObrigatoria = campoConfiguracao.obrigatorio;
      });
  }
}
