<template lang="html">
  <div style="width: 100%">
    <div :id="id" :name="id" style="width: 100%"></div>
    <div class="spreadsheet-rodape">
        <span>{{ $t('label.media') }}: {{ media }}</span>
        <span style="padding-left:10px">{{ $t('label.contagem') }}: {{ count }}</span>
        <span style="padding-left:10px;padding-right:20px">{{ $t('label.soma') }}: {{ soma }}</span>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';
import autocompleteMixin from './autocompleteMixin';
import modalMixin from './modalMixin';
import { toColumnName } from '../../common/functions/helpers';

export default {
  props: {
    id: { type: String, default: 'spreadsheet'},
    toolbar: Boolean,
    sheetsbar: Boolean,
    rows: Number,
    data: Array,
    colunas: Array,
    estilos: Array,
    formulas: Array,
    mergedCells: Array,
    filter: { type: Object, default: () => ({}) },
    selectRange: { type: String, default: 'A2' },
    tamanhoColunas: Array,
    resetSpreadsheet: Number,
    frozenRows: { type: Number, default: 0 },
    frozenColumns: { type: Number, default: 0 },
    toolbarList: Object,
    abasListaValores: {
      type: Array,
      default: () => [],
    },
    disabledSort: false,
    ignoredColumnsOnPaste: {
      type: Array,
      default: () => ([]),
    },
  },
  mixins: [autocompleteMixin, modalMixin],
  data() {
    return {
      grid: {},
      resetHit: 0,
      soma: 0,
      media: 0,
      count: 0,
      texto: '',
    };
  },
  watch: {
    resetSpreadsheet(hit) {
      if(this.resetHit === hit) return;
      this.rebuild();
    },
  },
  methods: {
    aplicarMetodos(lista) {
      const sheet = this.grid.activeSheet();

      sheet.batch(() => {
        lista.forEach((item) => {
          Object.keys(item.metodos).forEach((chave) => {
            sheet.range(item.range)[chave](item.metodos[chave]);
          });
        });
      }, { layout: true });
    },
    criar(ds) {
      const sheets = [
        {
          columns: this.tamanhoColunas,
          frozenRows: this.frozenRows,
          frozenColumns: this.frozenColumns,
          mergedCells: this.mergedCells,
          filter: this.filter,
        },
      ];
      this.abasListaValores.forEach((lista) => {
        const { nomeLista, valores } = lista;
        sheets.push({
          name: nomeLista,
          rows: [
            {
              cells: valores
                .map(value => ({ value })),
            },
          ],
        });
      });
      
      const outerRef = this;
      this.grid = $(`#${this.id}`).kendoSpreadsheet({
        changing: this.emitirChanging,
        columns: this.tamanhoColunas.length,
        rows: this.rows,
        toolbar: this.toolbar ? (this.toolbarList ? this.toolbarList : this.toolbar) : this.toolbar,
        sheetsbar: this.sheetsbar,
        sheets,
        select: this.selecionar,
        paste: function(e) {
          const fullData = e.clipboardContent.data;
          if (fullData.length < 50){
            return;
          }
          e.preventDefault();

          const currentRange = e.range;
          const mergedCells = e.clipboardContent.mergedCells;
          const topLeft = currentRange.topLeft();
          const initialRow = topLeft.row;
          const initialCol = topLeft.col;
          const origRef = e.clipboardContent.origRef;
          const numberOfRows = origRef.bottomRight.row - origRef.topLeft.row + 1;
          const numberOfCols = origRef.bottomRight.col - origRef.topLeft.col + 1;
          const spread = e.sender;
          const sheet = spread.activeSheet();
          const rangeToPaste = sheet.range(initialRow, initialCol, numberOfRows, numberOfCols);

          sheet.batch(function() {
            for(let i = 0; i < fullData.length; i += 1) {
              const currentFullData = fullData[i];

              for(let j = 0; j < currentFullData.length; j += 1 ) {
                const range = sheet.range(initialRow + i, initialCol + j);
                const value = currentFullData[j].value;

                if (value !== null) {
                  range.input(value);
                  range.format(null);
                }
              }
            }
          });

          sheet.select(rangeToPaste);

          for(let i = 0; i < mergedCells.length; i += 1) {
            const initialMergedRange = sheet.range(mergedCells[i]);
            const mergeTopLeft = initialMergedRange.topLeft();
            const mergeInitialRow = mergeTopLeft.row + initialRow;
            const mergedInitialCol = mergeTopLeft.col + initialCol;
            const mergedNumberOfRows = initialMergedRange.values.length;
            const mergedNumberOfCols = initialMergedRange.values()[0].length;

            sheet.range(mergeInitialRow, mergedInitialCol, mergedNumberOfRows, mergedNumberOfCols).merge();
          }

          setTimeout(() => outerRef.updateDataSource());
        },
      }).data('kendoSpreadsheet');

      const sheet = this.grid.activeSheet();
      const datasource = new kendo.data.DataSource({
        data: ds || this.data,
      });
      sheet.setDataSource(datasource, this.colunas);
      sheet.range(this.selectRange).select();

      datasource.bind("change", this.emitirChange);
      datasource.fetch();

      this.aplicarMetodos(this.formulas || []);
      this.aplicarMetodos(this.estilos || []);

      this.grid.refresh();
    },
    updateDataSource() {
      const sheet = this.grid.activeSheet();
      const range = `A2:${toColumnName(this.colunas.length)}${this.rows}`;

      const sheetRawContent = sheet.range(range).values();

      const dataSourceContent = sheetRawContent.map((row) => {
        const dataSourceEntry = {};
        row.forEach((c, index) => {
          if (c === '\n' || !this.colunas[index]) return;

          const { field } = this.colunas[index];
          if (this.ignoredColumnsOnPaste.indexOf(field) >= 0) {
            return;
          }
          dataSourceEntry[field] = typeof c === 'string' ? c.trim() : c;
        });

        return dataSourceEntry;
      });

      sheet.dataSource.data(dataSourceContent);
    },
    recriar() {
      const datasource = this.getDataSource();
      this.destruir();
      this.criar(datasource);
      if (this.autocompleteHabilitado()) {
        window.setTimeout(this.iniciaAutocomplete, 1000);
      }
      if (this.modalHabilitado()) {
        this.iniciaModal();
      }
    },
    destruir() {
      this.grid.destroy();
      $(`#${this.id}`).empty();
    },
    rebuild() {
      this.destruir();
      this.criar();
    },
    getDataSource() {
      return this.getFullDataSource().data();
    },
    getFullDataSource() {
      const sheet = this.grid.activeSheet();
      return sheet.dataSource;
    },
    getRangeSelected() {
      const sheet = this.getSheet();
      const range = sheet.selection();
      return range;
    },
    getValueSelected() {
      const sheet = this.getSheet();
      const range = sheet.selection();
      const values = range.values();
      return values;
    },
    getActiveCell() {
      const sheet = this.getSheet();
      const { bottomRight } = sheet.activeCell();
      const { col, row } = bottomRight;
      return `${toColumnName(col)}${row + 1}`;
    },
    getSheet() {
      return this.grid.activeSheet();
    },
    emitirChange(e) {
      this.$emit('DATASOURCE_CHANGE', e);
    },
    emitirChanging(e) {
      this.$emit('DATASOURCE_CHANGING', e);
    },
    exportarExcel() {
      this.grid.saveAsExcel();
    },
    startKendoUI() {
      window.kendo.culture('pt-BR');
      this.criar();
      if (this.autocompleteHabilitado()) {
        window.setTimeout(this.iniciaAutocomplete, 1000);
      }
      if (this.modalHabilitado()) {
        this.iniciaModal();
      }
    },
    selecionar(e) {
      this.soma = new Number(0);
      this.media = new Number(0);
      this.count = new Number(0);

      e.range.values().forEach((item) => {
        this.count += item.length;
        item.forEach((i) => {
          if (!isNaN(i) && i !== null && i !== '') {
            this.soma = this.soma + new Number(i);
            this.media = new Number((this.soma / this.count).toFixed(2));
          }
        });
      });

      this.soma = new Number(this.soma.toFixed(2));
      if (this.autocompleteHabilitado()) {
        this.pickDatasource(e.range);
      }
    },
    monta() {
      return flagValues.map(value => ({ value }));
    },
  },
  mounted() {
    window.JSZip = JSZip;
    if (window.kendo) {
      this.startKendoUI();
      return;
    }

    $.getScript('static/js/kendoui/kendoui.js', () => {
      $.getScript('static/js/kendoui/kendo.messages.pt-PT.js', () => {
        this.startKendoUI();
      });
    });
  },
  beforeDestroy() {
    if (this.disabledSort) {
      document.body.classList.remove('spreadsheet-disabled-sort');
    }
  },
  beforeMount() {
    if (this.tamanhoColunas === undefined)
      throw "Tamanho básico das colunas é obrigátório";
    if (this.data === undefined)
      throw "Datasource(data) é obrigatório";
    if (this.selectRange === undefined)
      throw "SelectRange é obrigatório"

    if (this.disabledSort) {
      document.body.classList.add('spreadsheet-disabled-sort');
    }
  },
};

</script>

<style lang="css">
.spreadsheet-rodape {
  background-color: #e9e9e9;
  text-align: right;
  font-size: 12px;
}
.spreadsheet-disabled-sort .k-spreadsheet-filter-menu li[data-command="sort"] {
  display: none;
}
</style>
<style src="./kendoui/kendo.common.min.css"></style>
<style src="./kendoui/kendo.default.min.css"></style>
<style src="./kendoui/kendo.default.mobile.min.css"></style>
